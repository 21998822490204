header.header{
	position: fixed;
	width: 100%;
	z-index: 999;
	top: 0px;
}
.main-content{
	display: table;
	width: 100%;
}
.sidebar{
	height: 100%;
	display: table-cell;

}
.page-content{
	
}

.box-container{
	padding: 15px;
}
.half-width{
	width: 49% !important;
}

.loading-placeholder{

	text-transform: uppercase;
	padding: 200px 50%;
}
#editor {overflow:scroll; max-height:300px}