.decorated-banner,
.decorated-thumbs-wrapper {
    @include flexbox();
    width: 100%;
    height: 100%;
    flex-direction: row;
    flex-wrap: wrap;

    .col.main {
        width: 50%;
        height: 100%;
        overflow: hidden;
        padding: 0px;
    }

    .thumb-wrapper {
        width: 50%;
        height: 50%;
        box-sizing: border-box;
        overflow: hidden;
    }

    .zoomable {
        transition: all 0.5s linear;
        cursor: pointer;

        &:hover {
            transform: scale(1.1);
        }
    }
}