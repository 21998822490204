@mixin border-radius($r) {
	border-radius: $r;
}

@mixin noselect() {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

}

@mixin transition($t) {

	-webkit-transition: $t;
	-moz-transition: $t;
	-ms-transition: $t;
	transition: $t;
}

@mixin transform($t) {

	-webkit-transform: $t;
	-moz-transform: $t;
	-ms-transform: $t;
	transform: $t;
}



//
// Mixins
// ------------------------------


// Utilities

@mixin size($width, $height) {
	width: $width;
	height: $height;
}

@mixin square($size) {
	@include size($size, $size);
}

@mixin border-top-radius($radius) {
	border-top-right-radius: $radius;
	border-top-left-radius: $radius;
}

@mixin border-right-radius($radius) {
	border-bottom-right-radius: $radius;
	border-top-right-radius: $radius;
}

@mixin border-bottom-radius($radius) {
	border-bottom-right-radius: $radius;
	border-bottom-left-radius: $radius;
}

@mixin border-left-radius($radius) {
	border-bottom-left-radius: $radius;
	border-top-left-radius: $radius;
}


// Vendor Prefixes

@mixin animation($animation) {
	-webkit-animation: $animation;
	-o-animation: $animation;
	animation: $animation;
}

@mixin box-sizing($boxmodel) {
	-webkit-box-sizing: $boxmodel;
	-moz-box-sizing: $boxmodel;
	box-sizing: $boxmodel;
}



//FLEX

@mixin flexbox {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
}

%flexbox {
	@include flexbox;
}

//----------------------------------

@mixin inline-flex {
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -moz-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
}

%inline-flex {
	@include inline-flex;
}

@mixin flex-direction($value: row) {
	@if $value==row-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: horizontal;
	}

	@else if $value==column {
		-webkit-box-direction: normal;
		-webkit-box-orient: vertical;
	}

	@else if $value==column-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: vertical;
	}

	@else {
		-webkit-box-direction: normal;
		-webkit-box-orient: horizontal;
	}

	-webkit-flex-direction: $value;
	-moz-flex-direction: $value;
	-ms-flex-direction: $value;
	flex-direction: $value;
}

// Shorter version:
@mixin flex-dir($args...) {
	@include flex-direction($args...);
}



@mixin flex-wrap($value: nowrap) {
	// No Webkit Box fallback.
	-webkit-flex-wrap: $value;
	-moz-flex-wrap: $value;

	@if $value==nowrap {
		-ms-flex-wrap: none;
	}

	@else {
		-ms-flex-wrap: $value;
	}

	flex-wrap: $value;
}


@mixin flex-flow($values: (row nowrap)) {
	// No Webkit Box fallback.
	-webkit-flex-flow: $values;
	-moz-flex-flow: $values;
	-ms-flex-flow: $values;
	flex-flow: $values;
}


@mixin flex-grow($int: 0) {
	-webkit-box-flex: $int;
	-webkit-flex-grow: $int;
	-moz-flex-grow: $int;
	-ms-flex-positive: $int;
	flex-grow: $int;
}


@mixin justify-content($value: flex-start) {
	@if $value==flex-start {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
	}

	@else if $value==flex-end {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
	}

	@else if $value==space-between {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
	}

	@else if $value==space-around {
		-ms-flex-pack: distribute;
	}

	@else {
		-webkit-box-pack: $value;
		-ms-flex-pack: $value;
	}

	-webkit-justify-content: $value;
	-moz-justify-content: $value;
	justify-content: $value;
}

// Shorter version:
@mixin flex-just($args...) {
	@include justify-content($args...);
}