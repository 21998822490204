$minWidth : 370px;


.footer {
	background: $footer-background;
	padding-top: 35px;
	width: 100%;
	bottom: 0;

	.footer_wrapper>.row {
		position: relative;
	}

	.footer_menu {
		.menu-title {
			color: #444;
			font-weight: bold;
			margin-bottom: 15px;
		}

		a,
		a:HOVER,
		a:VISITED {
			color: #FFF;
			opacity: 0.75;
			display: block;
			line-height: 30px;

			&.active {
				color: $gold-bright !important;
			}
		}
	}

	hr {
		border-color: #FFF;
	}

	.address {
		color: #fff;
		font-size: 14px;
		font-style: italic;
		margin-bottom: 25px;
		line-height: 30px;
	}


	.footer_ribbon {
		background: $footer-ribbon;
		color: #FFF;
		font-size: 14px;
		padding-top: 10px;
		line-height: 20px;

		.social_link a {
			font-size: 24px;
			text-decoration: none;
			color: #FFF;
		}
	}

	.subscribe_form {
		color: #FFF;
		// min-width: $minWidth;


		label {
			font-style: italic;
			font-size: 18px;
		}

		input {
			background: transparent;
			border: none;
			display: block;
			outline: none;
			color: inherit;
			border-bottom: 1px solid #FFF;
			height: 40px;
			line-height: 100%;
			margin-bottom: 10px;

			&::-webkit-input-placeholder,
			&:-ms-input-placeholder,
			&::placeholder {
				color: #ccc;
			}
		}

		::-webkit-input-placeholder {
			/* Edge */
			color: #ccc;
		}

		:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: #ccc;
		}


		::placeholder {
			color: #ccc;
		}

		.button {
			background: $gold-bright;
			border-color: $gold-bright;
			color: $dark-gray;
		}

	}

	.footer_menu a {
		color: #fff !important;
		cursor: pointer;
	}

	.currency-switcher {
		margin-top: 40px;
		display: block;

		.label {
			color: #fff;
		}

		.switch {
			margin-left: 20px;
			display: inline-block;
			background: $footer-ribbon;
			@include border-radius(4px);
			padding: 3px;
			font-size: 14px;
			line-height: 28px;
		}

		.currency {
			@include border-radius(3px);
			width: 50px;
			display: inline-block;
			color: #ccc;
			cursor: pointer;
			text-align: center;
			transition: all 0.25s linear;
		}

		.active {
			background: $gold-bright;
			color: $footer-ribbon;
		}
	}

}