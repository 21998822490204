.intro-modal{
    .modal-header{
        border-bottom: 2px solid #CB9A22;
        background: #F0F0F0;
        border-radius: 20px 20px 0px 0px;
        overflow: hidden;
    }
    .modal-title{
        width: 100%
    }
}