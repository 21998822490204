.react-tabs {
	width: 100%;

	.react-tabs__tab-list {
		padding: 0px;
		border-bottom: 1px solid #edece7;
	}

	.react-tabs__tab-panel {
		margin-top: 15px;
	}

	.react-tabs__tab {
		border: none;
		display: inline-block;
		line-height: 30px;
		font-size: 18px;
		font-weight: 500;
		margin-right: 30px;
		position: relative;
		color: #707070;
		cursor: pointer;
		
		&.react-tabs__tab--selected {
			color: #CB9A22;
			font-weight: 600;
			border-bottom: 2px solid#CB9A22;
		}

	}


}