.sidebar{
	width: 100px;

	.container{
		width: 100%;
		padding: 30px
	}
}


.sidebar-menu-group{

	margin-top: 30px;

	.title{
		opacity: 0.4;
		font-size: 11px;
		letter-spacing: 1.7px;
		color: #ffffff;
		margin-bottom: 15px;
		text-transform: uppercase;
	}

	.nav-menu-item{
		padding-left: 0px;
		margin-bottom: 15px;
		display: block;
		
		a{
			color: #FFF;
			font-size: 11px;
			line-height: 20px;
			font-weight: 300;
		}
	}
}