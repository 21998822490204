h1.section-title{
	font-size: 24px;
	padding: 75px 0 65px;
	text-transform: capitalize;
}
.homepage-section{
	text-align: center;
	padding-bottom: 65px;
}
.homepage-section-message{
	font-size: 20px;
	margin-bottom: 55px;
}
.dark_bg{
	background-color: $dark-bg-section;
	border-top: $dark-bg-top-border;
}
.align-left{text-align: left;}
.align-right{text-align: right;}

.looking-to-rent{
	h1{
		margin-top: 75px;
		font-size: 32px;
		font-weight: 700;
	}

	div{
		font-style: italic;
		font-size: 30px;
	}
}

.bullet-nav{
	position: fixed;
	right: 15px;
	top: 50%;
	margin-top: -135px;
	
	a.bullet, a.bullet:HOVER{
		width: 24px;
		height: 24px;
		display: block;
		font-size: 10px;
		text-align: center;
		cursor:  pointer;
		
		.label{ 
			position: absolute;
			white-space: nowrap;
			text-align: right;
			transition: all 200ms ease-in;
			left: 300px;
			width: 300px;
			color: #b5b5b5;
			line-height:  10px;
		}

		&:HOVER .label{
			left: -300px;
		}

		.circle{
			width: 10px;
			height: 10px;
			border:  2px solid #ebebeb;
			border-radius: 10px;
			display: block;
			margin: 0 auto;
		}

		&.active{
			.circle{
				color: #d7d7d7;
				border: 3px solid #d7d7d7;
				width: 12px;
				height: 12px;
			}
		}
	}


}
.contact-form.homepage-section{
	background: #F7F6F2;

	.section-title{
		font-size: 22px;
		font-style: italic;
	}
	input, textarea{
		border-bottom-color: lighten(#aaa390, 5%);
	}
	textarea{
		// height: 109px;
	}
}

.rental-east-jerusalem-banner{
	// background: url(../../../public/images/landing/photo1.jpg) no-repeat center center;
	background-size: cover;
	width: 100%;
	text-align: center;
	height: 350px;
	cursor: pointer;
	
	.main-light{
		font-size: 32px;
		color: white;
		padding-top: 75px;
	}
	.main{
		font-size: 32px;
		color: $gold-light;
		font-style: italic;
	}

	.divider{
		width: 200px;
		height: 2px;
		background: white;
		margin: 20px auto;
	}
	.sub{
		font-size: 20px;
		font-style: italic;
		color: white;
	}

	&.landing{
		height: 628px;
		border-bottom: 6px solid $gold-light;
		cursor: default;

		.main-light{
			padding-top: 215px;
			font-size: 48px;
		}
		.main{
			font-size: 56px;
		}

		.divider{
			margin: 40px auto;
		}
		.sub{
			font-size: 33px;
		}
	}

	
	
}

.featured-slider-mobile{
	margin-bottom: 50px;
}