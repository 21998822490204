.aboutus-page {

	padding-top: 50px;

	.title {
		font-size: 22px;
		padding-bottom: 30px;
	}

	.subtitle {
		opacity: 0.8;
		font-size: 16px;
	}

	.section {
		transition: all 0.3s ease-in-out;
		opacity: 0;
		height: 0px;
		overflow: hidden;

		&.showing {
			opacity: 1;
			height: auto;
		}
	}

	.navlinks .link {
		color: #676767;
		padding-bottom: 30px;
		font-size: 18px;
		font-weight: 700;
		cursor: pointer;

		&.active {
			color: $gold;
		}

		a {
			color: inherit;
			text-decoration: none;
		}
	}

	.who-we-are {
		.tarek {
			font-weight: bold;
			padding: 20px 0px 5px;
		}

		.block {
			border: 1px solid #e1e1e1;
			width: 360px;
			@include border-radius(5px);
			height: 90px;
			margin-right: 30px;
			margin-bottom: 30px;
			display: inline-block;
		}

		.block-icon {
			display: inline-block;
			font-size: 90px;
			color: $gold;
			padding: 0px 15px;
			vertical-align: middle;
		}

		.text {
			display: inline-block;
			vertical-align: middle;
			width: 210px;
			line-height: 21px;

			.accent {
				font-weight: 700;
				font-size: 28px;
			}
		}

		.contact-wrapper {
			@include flexbox();
			margin-bottom: 20px;

			img {
				align-self: flex-start;
				margin-right: 35px;
			}
		}
	}


}


.navlinks {
	position: fixed;
}

.organizations {
	padding-bottom: 50px;

	.logo {
		margin-top: 20px;
		margin-right: 15px;
	}

}
