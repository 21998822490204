.customer{
    @include flexbox();
    margin: 45px 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    
    // &.right{
    //     background: url('/images/gradient.png') no-repeat;
    //     padding-right: 140px;
    // }
    .fa, .content{
		vertical-align: top;
	}
	.fa{
		color: #e6dec7;
		font-size: 34px;
		margin-right: 30px; 
		display: block;
		width: 50px;
		height: 50px;
	}

	.name{
        font-size: 18px;
        font-weight: bold;
		font-style: italic;
		padding-bottom: 10px;
    }
    .position{
        font-size: 14px;
    }
    // &.right{
    //     direction: rtl;
    //     .fa{
    //         width: 30px;
    //         margin-right: 0px;
    //         margin-left: 30px;
    //     }
    // }
}