.image-upload {
	margin-top: 15px;
	margin-bottom: 20px;
	display: inline-block;
}

.preview-container {
	display: inline-block;
	vertical-align: top;

	.photo-thumb {
		display: inline;
		vertical-align: top;

	}

	&.Droppable {
		display: inline-block;
		width: 5px;
		height: 125px;
		margin-right: 2px;
		margin-left: 2px;
	}

	&.Droppable.over {
		width: 25px;
		background: #efefef;
		border: 3px dashed #ccc;

	}

	.image-thumb {
		border: 1px solid #ccc;
		height: 125px;
		width: 150px;
		margin-bottom: 15px;
		font-size: 0.8em;
		position: relative;
		text-align: center;
		display: inline-block;
		vertical-align: top;

		img {
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 100%;
			display: inline-block;
			margin: 0 auto;
		}

		.close-btn {
			position: absolute;
			bottom: -14px;
			right: 5px;
			background: $grey-brown;
			color: #FFF !important;
			padding: 0px 10px;
			display: inline-block;
			font-size: 0.75em;
			line-height: 1.25em;
			@include border-radius(0px 0px 4px 4px);
			cursor: pointer;
		}

		input {
			width: 100%;
			position: absolute;
			bottom: 0px;
			left: 0px;
			box-sizing: border-box;
			height: 26px;
			line-height: 26px;
			font-size: 0.8em;
			text-indent: 5px;
		}
	}
}

.upload-zone {
	background: #c6dbf8;
	padding: 5px;
	width: 160px;
	text-align: center;
	color: #666;
	fill: #999;
	font-size: 12px;
	display: inline-block;
	margin: 0px 10px;

	.upload-zone-content {
		padding: 15px;
		border: 2px dotted #999;
		cursor: pointer;

		.box__icon {
			padding: 15px;
		}
	}
}

.progress {
	background: lighten(#ccc, 15%);
	@include border-radius(3px);
	overflow: hidden;
}

.progress-bar {
	height: 100%;
	background: $gold;
}