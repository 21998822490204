@font-face {
  font-family: 'icomoon';
  src:  url('./fonts/icomoon.eot');
  src:  url('./fonts/icomoon.eot#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf') format('truetype'),
    url('./fonts/icomoon.woff') format('woff'),
    url('./fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-unfurnished .path1:before {
  content: "\e920";
  color: rgb(51, 51, 51);
}
.icon-unfurnished .path2:before {
  content: "\e921";
  margin-left: -0.955078125em;
  color: rgb(51, 51, 51);
}
.icon-unfurnished .path3:before {
  content: "\e922";
  margin-left: -0.955078125em;
  color: rgb(51, 51, 51);
}
.icon-unfurnished .path4:before {
  content: "\e923";
  margin-left: -0.955078125em;
  color: rgb(51, 51, 51);
  opacity: 0.5;
}
.icon-unfurnished .path5:before {
  content: "\e924";
  margin-left: -0.955078125em;
  color: rgb(51, 51, 51);
  opacity: 0.5;
}
.icon-back-arrow:before {
  content: "\e900";
}
.icon-previous:before {
  content: "\e901";
}
.icon-bathroom .path1:before {
  content: "\e902";
  color: rgb(51, 51, 51);
}
.icon-bathroom .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(51, 51, 51);
  opacity: 0.5;
}
.icon-bedroom .path1:before {
  content: "\e904";
  color: rgb(51, 51, 51);
}
.icon-bedroom .path2:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(51, 51, 51);
  opacity: 0.5;
}
.icon-car:before {
  content: "\e906";
}
.icon-handshake:before {
  content: "\e907";
}
.icon-email:before {
  content: "\e908";
}
.icon-facebook-circle:before {
  content: "\e909";
}
.icon-facebook:before {
  content: "\e90a";
}
.icon-first_btn:before {
  content: "\e90b";
}
.icon-furnished .path1:before {
  content: "\e90c";
  color: rgb(51, 51, 51);
}
.icon-furnished .path2:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(51, 51, 51);
  opacity: 0.5;
}
.icon-heart:before {
  content: "\e90e";
}
.icon-linkedin:before {
  content: "\e90f";
}
.icon-info:before {
  content: "\e910";
}
.icon-instagram:before {
  content: "\e911";
}
.icon-last_btn:before {
  content: "\e912";
}
.icon-neighbour:before {
  content: "\e913";
}
.icon-next:before {
  content: "\e914";
}
.icon-properties:before {
  content: "\e915";
}
.icon-property:before {
  content: "\e916";
}
.icon-search:before {
  content: "\e917";
}
.icon-send_doc:before {
  content: "\e918";
}
.icon-size:before {
  content: "\e919";
}
.icon-sorting:before {
  content: "\e91a";
}
.icon-transfer:before {
  content: "\e91b";
}
.icon-twitter:before {
  content: "\e91c";
}
.icon-UN:before {
  content: "\e91d";
}
.icon-user:before {
  content: "\e91e";
}
.icon-send-email:before {
  content: "\e91f";
}

