.results.list {
    padding-left: 15px;
    padding-right: 15px;

    .tile-info .props {
        display: table;

        .row {
            display: table-row;
        }

        .caption,
        .value {
            display: table-cell;
        }
    }

    .tile-photo-wrapper {
        @include flexbox();
        cursor: pointer;
    }

    .banner-meta {
        width: 332px !important;
    }

    .tile-wrapper {
        padding-bottom: 15px;
        border-bottom: 1px solid $grey-brown;
        position: relative;

        .recommended {
            width: 50px;
            right: 0px;
            top: 0px;
        }
    }

    .info-section .block .value {
        font-size: 14px;
    }

    .tile-photo-wrapper {
        @include flexbox();
        overflow: hidden;

        &:HOVER {
            text-decoration: none;
        }

        div.banner-meta {
            width: 350px !important;
            margin-right: 20px;
            flex-grow: 0;
            flex-shrink: 0;

            .status {
                transition: all 200ms linear;
                margin-top: 10px;
                top: 0;
            }

            &:HOVER {
                .status {
                    transform: translateY(-40px);
                }

                .propId {
                    transform: translateY(40px);
                }

                .image-gallery-left-nav {
                    transform: translate(0px, -50%);
                }

                .image-gallery-right-nav {
                    transform: translate(0px, -50%);
                }
            }

        }

        .tile-content {
            position: relative;
            width: 100%;
            color: #666;

            display: flex;
            flex-direction: column;

            .title {
                font-size: 1.3em;
                font-weight: 600;
                padding-bottom: 20px;
                max-width: 90%;
            }

            .summary,
            .prop-description {
                max-height: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                display: block;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                font-size: 0.85em;
                margin-bottom: 10px;
            }

            .property-address {
                font-size: 13px;
                font-weight: normal;
                display: block;
            }

            .tile-info {
                align-self: baseline;
            }

        }

        .propId {
            bottom: 20px;
            transition: all 200ms linear;
        }
    }

    .props {
        position: absolute;
        bottom: 0px;
        width: 100%;

        div {
            padding: 2px 3px;
            font-size: 13px;
        }

        .odd {
            background: #f3f2ec;
        }

        .caption {
            width: 100px;
        }

        .value {
            font-weight: bold;
            text-transform: capitalize;
            min-width: 100px;
        }

        .icon-info {
            position: absolute;
            left: 35px;
            margin-top: -22px;
            z-index: 1;
        }

        .price-currency {
            background: $greenish;
            color: white;
            border-radius: 4px;
            padding: 2px 8px;
            display: inline-block;
            margin: 4px 0px;
        }
    }
}