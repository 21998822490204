.big-button {
	background: lighten($gold-bright, 40%);
	border: 1px solid $gold-bright;
	border-radius: 4px;
	display: flex;
	width: 100%;
	height: 180px;
	position: relative;
	// text-align: center;
	align-items: center;
	justify-content: center;

	.new-button {
		position: absolute;
		bottom: 15px;
		right: 15px;
		font-size: 0.75em;
	}
}

.modal-backdrop:has(~ .comments-modal-admin){
	z-index: 11;
}
.comments-modal-admin {

		.pac-container {
			background-color: #FFF;
			z-index: 20;
			position: fixed;
			display: inline-block;
			float: left;
		}
		 	z-index: 20 !important;   
		& .modal-backdrop{
			z-index: 10;        
		}}