@import 'amenities_nearby';
@import 'backtotop';
@import 'banner';
@import 'description';
@import 'editProperty';
@import 'furniture_tooltip';
@import 'header';
@import 'info';
@import 'media';
@import 'propsInBuilding';
@import 'testimonials';
@import 'decorated_banner';

.property-page{
    .contact-form{
        margin-top: 30px;
    }
}