.react-datepicker-wrapper{
    width: 100%;
    .react-datepicker__input-container{
        border: 1px solid #CBCACA;
        border-radius: 4px;
        height:  40px;

        input{
            width:  100%;
            height:26px;
            line-height:100%;
            border: 0;
            background: transparent;
            text-indent: 8px;
            outline: none;
        }
    }
}