.tooltip-inner{
	margin-top: 10px;
}
.tooltip-arrow{
	border:  5px solid transparent;
	width: 10px;
	position: absolute;
	margin-left: -5px;
}
.bottom .tooltip-arrow{ 
	border-bottom-color: black; 
}
.top .tooltip-arrow{ 
	border-top-color: black; 
}
.left .tooltip-arrow{
	border-left-color: black;
	right: -10px;
	margin-top: -5px;
}
.tooltip.left{
	margin-left: -5px;
}