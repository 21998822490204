.description-section {
	position: relative;
}

.info-section {
	padding: 25px 0px;
	background: #f8f7f5;

	.price .value {
		color: $greenish;
		font-size: 18px;
		font-weight: 700;
		line-height: 20px;

	}

	.icon-info {
		font-size: 16px;
		vertical-align: sub;

		&:HOVER {
			color: $greenish;
		}
	}

	.block {
		display: inline-block;
		margin-right: 40px;
		vertical-align: top;

		.value {
			font-size: 19px;
			font-weight: 700;
			line-height: 16px;
			text-transform: capitalize;
		}

		.caption {
			font-size: 14px;
			margin-top: 5px;
		}
	}

	.price {
		float: right;
	}

}

.recommended {
	position: absolute;
	width: 85px;
	height: auto;
	top: -30px;
	right: 5px;
}

.price .tooltip-inner {
	text-align: left;
}

.additional-tooltip {
	white-space: nowrap;
	/* max-width: 200px; */
	line-height: 1.5em;
}

.additional-info-item {
	margin: 0px 3px;
	font-size: 13px;
	font-style: italic;

	&:not(:last-child):AFTER {
		content: ",";
	}
}

.block.neighborhood {
	margin-left: 30px;
}

.page-section {
	h2 {
		font-size: 21px;
		font-weight: 700;
		font-style: italic;
		margin-bottom: 40px;
	}
}

.description,
.details {
	position: relative;
	margin-top: 60px;
	max-width: 600px;
}

.details {
	.feature {
		border-bottom: 1px solid #eaeaea;
		font-size: 15px;
		font-weight: bold;
		padding: 10px 0px;

		.value {
			color: $gold;
			float: right;
			text-transform: capitalize;
		}

		.icon-info {
			float: right
		}
	}

	.exclusive {
		position: absolute;
		top: -30px;
		right: 0px;
	}
}

a.media-link {
	background: $grey-brown;
	color: #FFF !important;
	float: right;

	&:hover {
		background: $grey-brown;
	}
}