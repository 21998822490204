.property-banner {

	border-bottom: 4px solid $gold-light;
	position: relative;

	.image-gallery-fullscreen-button {
		display: none;
	}
}

.hide-gallery {

	.image-gallery,
	.image-gallery-content,
	.image-gallery-slide-wrapper,
	.image-gallery-swipe,
	.image-gallery-slides {
		height: 100%;
	}

	.image-gallery-slide img {
		margin-top: -20%;
	}

	.image-gallery-thumbnails-wrapper,
	.image-gallery .tour {
		display: none;
	}

	.image-gallery {
		position: absolute;
		width: 100%;
	}

}

.banner-meta-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.banner-meta {
	position: relative;
	height: 100%;

	.status {
		height: auto;
		background: rgba(0, 0, 0, 0.7);
		border-radius: 3px;
		padding-right: 20px;
		line-height: 26px;
		padding-left: 10px;
		right: 10px;
		position: absolute;
		color: #ccc;

		&:after {
			content: '';
			display: inline-block;
			position: absolute;
			width: 10px;
			height: 21px;
			right: 4px;
			margin-top: 3px;
			border-radius: 2px;
		}

		.label {
			font-size: 0.9em;
			font-weight: 400;
			font-style: normal;
		}

		margin-top: 0px;
		top: 10px;

		.duration {
			padding-right: 2px;

			&:not(:first-child):before {
				content: "-"
			}
		}

		&.available:AFTER {
			background: #07a99d;
		}

		&.unavailable:AFTER {
			background: #cd404a;
		}

		&.soon:AFTER {
			background: $gold;
		}

		.available-on {
			position: absolute;
			line-height: 18px;
			right: 25px;
			background: rgba(0, 0, 0, 0.5);
			padding: 1px 3px;
			font-size: 0.8em;
			border-radius: 0 0 3px 3px;
		}
	}


	.propId {
		position: absolute;
		bottom: 10px;
		right: 15px;
		background: $gold-light;
		padding: 10px;
		@include border-radius(4px);
		min-width: 100px;
		line-height: 15px;
		text-align: center;
	}

	.show-gallery {
		color: #efefef;
		position: absolute;
		bottom: 10px;
		left: 15px;
		cursor: pointer;
	}
}

.tour {
	z-index: 999;
	pointer-events: all;
	// background: rgba(0, 0, 0, 0.5);
	// padding: 0px 15px;
	// border-radius: 4px;
	margin: 10px;
	position: absolute;

	img,
	span {
		display: inline-block;
		vertical-align: middle;
	}

	img {
		margin-right: 10px;
	}

	span {
		color: white;
	}
}

.fullscreen {

	.tour,
	.image-gallery-fullscreen-button {
		display: block;
	}
}

.property-banner {
	.status {
		font-size: 18px;
		padding-top: 3px;
		padding-bottom: 3px;
		padding-right: 25px;
		color: #ccc;

		&:AFTER {
			height: 28px;
			margin-top: -1px;
			width: 12px;
		}
	}
}