.fwrap {
	background: beige;
	border: 1px solid darken(beige, 15%);
	float: left;
	margin: 5px;
	display: inline-block;
	text-align: center;
	position: relative;

	img {
		max-width: auto;
		max-height: auto;
	}
}

.feature-properties.edit {
	width: 425px;
	display: inline-block;
	vertical-align: top;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;

	.featured-index {
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -5px;
		margin-top: -10px;
	}

	.fa-times {
		position: absolute;
		top: -10px;
		right: -10px;
	}

	.Droppable {
		width: 30%;
		display: inline-block;

	}

	.fwrap {
		width: 100%;
		height: 100px;
	}



}

.Droppable.over {
	.fwrap {
		background: darken(beige, 15%);
		border: 1px dashed orange;

	}
}

.all-props,
.fwrap {
	label {
		white-space: nowrap;
		font-size: 0.7em;
		max-width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		display: block;
		background: rgba(0, 0, 0, 0.5);
		color: #FFF;
		position: absolute;
		bottom: 0px;
		margin: 0;
		padding: 0 5px;
	}
}

.all-props {
	padding: 10px 50px;
	position: relative;

	.inner {
		max-height: 500px;
		overflow: auto;
	}

	.prop {
		width: 170px;
		height: 120px;
		border: 1px solid silver;
		display: inline-block;
		float: left;
		margin: 5px;
		overflow: hidden;
		position: relative;

		img {
			max-width: 100%;
		}

	}
}

.all-props {
	border: 3px dashed transparent
}

.over.all-props {
	background: lighten(#ccc, 15%);
	border-color: #ccc;
}

.select-props {
	.url-wrapper {
		margin-top: 15px;
		position: relative;

		.url-display {
			background: darken($dark-bg-section, 10%);
			border: 1px solid $footer-ribbon;
			border-radius: 4px;
			padding: 4px 8px;
			width: 100%;
			margin-bottom: 30px;
			font-style: italic;
			color: $footer-background;
			padding-right: 30px;
		}

		i {
			position: absolute;
			margin-left: -24px;
			margin-top: 9px;
			cursor: pointer;
		}

		.fa-trash {
			margin-left: 10px;
		}
	}
}

.selective.edit {
	border: 3px dashed $grey-brown;
	width: 425px;
	height: 500px;
	display: inline-block;
	vertical-align: top;
	font-size: 0;
	position: relative;
	overflow-y: auto;

	.fwrap {
		width: 100%;
		height: 100%;

		label {
			font-size: 12px;
		}
	}

	.Droppable {
		width: 194px;
		height: 100px;
		display: inline-block;
		margin: 5px;
	}

	.Droppable.over {
		background: lighten(#ccc, 15%);
	}
}