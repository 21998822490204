.item-page{
    .separator-line{
       
        width: 100%;
    }
    .property-summary{
        color: #06A99D;
        font-size: 15px;
    }
    .item-banner{
        margin-bottom: 60px;
        overflow: hidden;
        height: 300px;
        width: 100%;
        position: relative;
        border-bottom: 4px solid $gold-light;

        .banner-meta{
            height: auto;
            z-index: 2;
        }
        .inner-wrapper{
            display: table;
            position: relative;
            transition: all 0.5s ease-in;
        }

        .image-wrapper{
            display: table-cell;
            height: 300px;
            width: 33%;
            overflow: hidden;
            position: relative;
        }
        .fa{
            position: absolute;
            cursor: pointer;
            z-index: 1;
            font-size: 36px;
            top: 140px;
            &.fa-angle-right{ right: 15px;}
            &.fa-angle-left{ left: 15px;}
        }

    }
    .person-photo{
        width: 30px;
        height: 30px;
        display: inline-block;
        margin-right: 10px;
        img{
            width: 100%;
            height: auto;
        }

    }
    .item-description{
        
        margin-bottom: 40px;
        .section-content{
            font-size: 12px;
            line-height: 15px;
        }
        
    }
    .section-title{
        font-style: italic;
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 20px;
    }
    .image-gallery-content:not(.fullscreen){
        display: none;
    }

    .item-gallery-wrapper{
        max-width: 510px;
        margin-top: 15px;
        cursor: pointer;
        width: 100%;
        height: 100px;
        overflow: hidden;
        .inner-wrapper{
            display: flex;
        }
        .image-wrapper{
            max-width:200px;
            max-height: 120px;
            img{
                width: 120%;
                height: auto;
            }
        }
    }

    .posted{
        .divider{
            width: 100%;
            border-top: 1px solid #c69528;
            margin: 15px 0px;
        }
        .posted-by{
            font-style: italic;
            .fa-user-circle{color: #a79886; font-size: 22px; margin-right: 15px;}
        }
        .email, .mobile{font-size: 12px;}
        .label{
            font-weight: 700;
            margin-right: 5px;
        }
        .value{
            color: #07a99d;
        }
    }

    .similar-items{
        margin-top: 50px;
        .section-title{
            margin-left: 15px;
        }
        .similar{
            max-width: 200px;
            margin-right: 15px;
        }
        .inner{
            display: flex;
            flex-wrap: wrap;
        }
        .title{
            font-size: 12px;
        }
        .image-wrapper{
            max-width: 300px;
            max-height: 120px;
            overflow: hidden;
            img{
                width: 100%;
                height: auto;
            }
        }
    }
}