.customer-testimonial-wrapper {

	.text {
		font-style: italic;
		font-weight: normal;
		font-size: 14px;
	}

	.by {

		img {
			max-width: 150px;
			max-height: 80px;
			display: inline-block;
		}
	}
}

.property-testimonials {
	// background: #fafaf8;

	.section-title {
		font-size: 23px;
	}
}