@import 'vars';
@import 'mixin';
@import 'base';
@import 'fonts';
@import 'layout/layout';
@import 'components/components';
@import 'icons';

html,
body,
#root {
	height: 100%;
	width: 100%;
	padding: 0px;
	margin: 0px;
	font-family: 'Open sans', sans-serif;
	font-size: 13px;
	color: $default-font-color;
}


h1,
h2,
h3,
h4,
h5 {}

a,
a:NOT([href]),
a:NOT([href]):HOVER,
a:hover,
a:visited {
	color: $link-color;
	outline: none;
	cursor: pointer;
}

a:focus {
	outline: none;
	text-decoration: none;
}

a.button,
a.button:hover,
a.button:visited {
	background: $button-background;
	color: white;
	font-size: 18px;
	line-height: 18px;
	cursor: pointer;
	padding: 15px 40px;
	display: inline-block;
	text-decoration: none;
	@include border-radius(4px)
}

.btn-primary.disabled,
.btn-primary:disabled {
	background: $button-background;
	cursor: not-allowed;
}

a.button:hover {
	background: $gold;
}

.poi-info-window{
	padding:10px;
}
.tooltip-inner {
	max-width: 100%
}

.tooltip .arrow {
	height: 17px;
}

button.btn {
	margin: 0px 5px;
	color: #666;
}

button.btn-primary,
button.btn-danger {
	color: white;
}

button,
.btn {
	border-color: transparent;
	outline: none !important;
	border-color: transparent;

	&.btn-primary:disabled {
		border-color: transparent;
		cursor: not-allowed;
	}

	&,
	&:hover,
	&:active,
	&:focus {
		box-shadow: none;
		border-color: transparent;
	}
}

.site-content:not(.home){
	padding-top: 75px;
}

.jbutton,
.jbutton:HOVER,
.jbutton:VISITED {
	height: 30px;
	line-height: 30px;
	font-size: 14px;
	background: #676767;
	color: #fff;
	padding: 0 15px;
	@include border-radius(4px);
	cursor: pointer;
}

.root {
	.overlay {
		z-index: 11 !important;
	}

	.sidebar-class {
		z-index: 12 !important;
	}
}

.card {
	margin-bottom: 15px;
}

.DayPickerKeyboardShortcuts_buttonReset {
	display: none;
}

/*ZOHO SalesIQ*/
// .zls-small{display: none !important;}
#zsiqbtn {
	position: fixed;
	bottom: 20px;
	left: 30px;
}

.siqembed.zls-sptwndw {
	left: 30px !important;
	right: auto !important;
	top: auto !important;
}

.suspense {
	padding: 25% 50%;
}
.tippy-popper{
	max-width: 100% !important;
}
.tippy-tooltip{
	text-align: left !important;
	&.light-theme{
		padding: 0px !important;
	}
}
.tippy-box{
	border-radius: 10px !important;

}
.tippy-content{
	padding: 0 !important;
}
#tooltip {
	background: #FFF;
	padding: 4px 8px;
	font-size: 13px;
	border-radius: 4px;
  }

  #tooltip[data-popper-reference-hidden] {
	visibility: hidden;
	pointer-events: none;
  }

.modal-open{
	overflow: inherit !important;
	padding-right: 0px !important;
}
.gm-fullscreen-control{
	margin-top: 15px !important;
	margin-right: 12px !important;
}
.placeModal{
	z-index: 9999;
	.modal-content{
		border-radius: 8px !important;

	}
	.modal-body{
		padding: 0;
	}
	// @media (min-width: 992px){
	// 	.modal-lg, .modal-xl {
	// 		max-width: 900px;
	// 	}
	// }
}
