.pagination{
	margin: 0 0 0 30px;
	padding: 0;
	list-style: none;
	display: flex;
	font-size: 12px;
	outline: none;
	
	li{
		width: 18px;
		height: 18px;
		text-align: center;
		line-height: 18px;
		font-weight: bold;
		cursor: pointer;
		outline: none;

		a, a:HOVER{
			color: inherit;
			outline: none;
			text-decoration: none;
		}

		&.active{
			background: #676767;
			color: #fff !important;
			border-radius: 2px;

		}
	}

	.previous, .next{
		padding: 0px 15px;
	}
	.previous a, .next a{
		color: #676767;
	}
	.disabled a, .disabled a:HOVER{
		color: #cfcfcf;
		cursor: not-allowed;
	}
}