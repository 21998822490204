.no-gutter{

	> div:FIRST-CHILD{
		padding-left: 0px;
	}
	
	> div:LAST-CHILD{
		padding-right: 0px;
	}
}
.trim-text{
	display: inline-block;
	max-width: 150px;
	text-overflow: ellipsis;
	overflow: hidden;
	vertical-align: bottom;
	white-space: nowrap;
	margin-right: 5px;
}

.loading{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 9999;

	.overlay{
		background: rgba(0,0,0,0.5);
		width: 100%;
		height: 100%;	
	}
	
	.spinner{
		width: 60px;
		height: 60px;
		margin-top: -30px;
		margin-left: -30px;
		position: absolute;
		top: 50%;
		left: 50%;
		animation: rotate 1.4s infinite ease-in-out, background 1.4s infinite ease-in-out alternate;

		@keyframes rotate {
		  0% {
		    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
		  }
		  50% {
		    transform: perspective(120px) rotateX(-180deg) rotateY(0deg);
		  }
		  100% {
		    transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
		  }
		}
		@keyframes background {
		  0% {
		  background-color: #27ae60;
		  }
		  50% {
		    background-color: #9b59b6;
		  }
		  100% {
		    background-color: #c0392b;
		  }
		}
	}
}
.form-group{
	position: relative;
}

.modal.show .modal-dialog{
	box-shadow: 0px 2px 20px #00000080;
	// margin: 0px auto;
	top: 50%;
	transform: translateY(-50%);

	.modal-content{
		border: 1px solid #979797;
		border-radius:6px;
	}
}

@media (min-width: 576px){

	.modal-dialog {
		min-width: 550px;
	}
}