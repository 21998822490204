.apartments-in-buiding {
	padding-top: 30px;
	.filter-panel{
		position: relative;
		padding: 0px;
		.first-row{
			display: none;
		}
	}
	.sort-view{
		position: absolute;
		right: 0px;
		top: -35px;
		cursor: pointer;
	}
	.results{
		padding-top: 0px;
	}

	.search-page > .container{
		padding: 0px;
	}
}