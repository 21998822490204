@import './tileGallery';

.results.grid {
	.tiles {
		padding: 0px;
	}

	.tile-wrapper {
		width: 100%;
		min-width: 230px;
		@include border-radius(4px);
		background: #f8f7f5;
		display: inline-block;
		vertical-align: top;
		margin-bottom: 18px;
		box-sizing: border-box;
		position: relative;
		cursor: pointer;
		overflow: hidden;
		text-align: left;

		&:HOVER {
			text-decoration: none;
		}

		a.tile-photo-wrapper:HOVER,
		a.tile-photo-wrapper:VISITED {
			text-decoration: none;
		}

		.tile-photo-wrapper,
		.banner-meta {
			@include border-radius(4px 4px 0px 0px);

		}

		.tile-content {
			color: #666;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			padding: 10px;
		}




		.title,
		.subtitle {
			padding: 1px 10px;
		}

		.title {
			font-size: 15px;
			line-height: 26px;
			white-space: nowrap;
			max-width: 190px;
			overflow: hidden;
			text-overflow: ellipsis;
			font-weight: 400;
			color: #CCC;
			text-align: left;
			background: rgba(0, 0, 0, 0.7);
			border-radius: 3px;
			display: inline-block;
		}

		.subtitle {
			font-size: 15px;
			font-weight: 600;
		}

		.property-address {
			font-size: 12px;
			font-weight: 300;

			&:before {
				content: ",";
				padding-right: 5px;
			}
		}

		&.items {
			height: 340px;

			.subtitle {
				font-size: 12px;
				font-weight: 300;
			}

		}

		.attributes {
			font-size: 12px;
			font-weight: 600;
			margin: 5px 0;

			.item {
				margin-right: 15px;
				padding-right: 15px;
				color: #FFF;
				text-align: center;
				font-size: 0.9em;
				font-weight: 300;

				&:not(:last-child) {
					border-right: 1px solid rgba(255, 255, 255, 0.5);
				}
			}

			.item .figure {
				font-size: 16px;
				display: block;
				font-weight: 600;
				line-height: 17px;
			}

			.icon {
				font-size: 20px;
			}

			.per {
				float: right;
			}
		}

		.tile-attribs {
			position: absolute;
			bottom: 0px;
			left: 0;
			right: 0;
			padding: 25px 10px 10px;
			background: rgb(0, 0, 0);
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(255, 255, 255, 0) 100%);

			.stat {
				color: white;
				font-size: 0.75em;
				font-weight: 300;

				>span:not(:last-child):after {
					content: " / ";
				}

				.duration {
					text-transform: capitalize;
				}

			}
		}

		.price {
			font-weight: bold;
			color: #FFF;
			font-weight: 600;

			.value {
				font-size: 18px;
				vertical-align: bottom;
			}
		}

		.image-gallery-left-nav,
		.image-gallery-right-nav,
		.tile-content,
		.tile-attribs,
		.title,
		.status,
		.propId {
			transition: all 250ms linear;
		}

		.status {
			font-size: 0.85em;
		}


		&:hover {
			.image-gallery-left-nav {
				transform: translate(0px, -50%);
			}

			.image-gallery-right-nav {
				transform: translate(0px, -50%);
			}

			.tile-content {
				transform: translateY(130px);
				pointer-events: none;
			}

			.title {
				transform: translateY(-180px);
			}

			.status {
				transform: translateY(-80px);
			}

			.propId {
				transform: translateY(60px);
			}
		}
	}
}


.tile-wrapper {

	.image-gallery-left-nav,
	.image-gallery-right-nav {
		font-size: 28px;
		transition: all 250ms linear;
	}

	.propId {
		background: $gold-light;
		color: $dark-gray;
		font-weight: 600;
		padding: 2px 5px;
		font-size: 13px;
		min-width: 0px;
		position: absolute;
		right: 10px;
		bottom: 40px;
		z-index: 1;
	}

	.image-gallery-left-nav {
		transform: translate(-30px, -50%);
	}

	.image-gallery-right-nav {
		transform: translate(30px, -50%);
	}
}

.edit-prop-link,
.duplicate-prop-link {
	position: absolute;
	bottom: 10px;
	right: 5px;
	background: $grey-brown;
	border-radius: 4px;
	display: inline-block;
	color: #FFF;
	font-size: 0.6em;
	line-height: 15px;
	padding: 0px 5px;
	z-index: 1;
}

.duplicate-prop-link {
	right: 45px;
}

.icon.icon-unfurnished {
	font-size: 16px !important;
}

.building-content {

	text-align: center;

	a.building-tile:HOVER {
		text-decoration: none;
	}

	.info {
		color: #666;

		.title,
		.address {
			color: inherit;
		}

		.title {
			font-size: 1.4em;
			font-weight: bold;
		}
	}
}