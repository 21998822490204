.media{
	margin-top: 50px;
	padding: 30px 0px;

	.tabs.vertical{
		.tab-items > div {
			font-weight: 700;
		}
	}
	.tabs-content .image-gallery-content:not(.fullscreen){
		min-width: 940px;
		margin-bottom: 30px;
		.image-gallery-thumbnails{display: none;}
		.image-gallery-slide-wrapper{max-height: 400px; overflow: hidden;}
		.image-gallery-slide img{ margin-top:  -25%; }

	}
}
.gallery-wrapper{
	max-width: 1140px;

	.gallery{
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		
		.image-wrapper{
			margin-bottom: 3px;
			margin-right: 2px; 
			cursor: pointer;
			width: 270px;
			height: 180px;
			
			img{
				width: auto;
				height: auto;
				max-width: 100%;
				max-height: 100%; 
			}
		}
	}

	
}
.image-gallery-fullscreen-button:before{
	content: "" !important;
}
.image-gallery-fullscreen-button:after{
	content: "";
	display: inline-block;
	width: 31px;
	height: 30px;
	background: url(../../../../public/images/popout.png) no-repeat;
	margin-left: -60px; 
}
.fullscreen .image-gallery-fullscreen-button:after{
	background: url(../../../../public/images/popin.png) no-repeat;
}

.page-section.map-section{
	padding: 50px 0px;
}