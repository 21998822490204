.filtersModal{
	max-width: 760px;
	.modal-content{
		border-radius: 20px;

	}
}
.filter-panel {

	.first-row,
	.second-row {
		margin: 10px 0px;

	}

	.first-row .sort-view {
		margin-right: 10px;
		cursor: pointer;
	}

	.first-row,
	.second-row,
	.sorting {

		display: flex;
		align-content: center;
		align-items: center;
		font-size: 14px;
	}

	.line-break {
		width: 100%;
	}

	.tags {
		flex-grow: 3;
		font-size: 12px;
	}

	.items~.tags {
		max-width: 725px;
	}

	.tags {
		padding: 5px 15px;

		.filter-tags {
			display: flex;
			flex-wrap: wrap;
		}

		.tag {
			font-size: 12px;
			line-height: 12px;
			padding: 5px 10px;
			background: #ebebeb;
			color: #676767;
			margin-right: 10px;
			margin-bottom: 3px;
			font-weight: normal;
			white-space: nowrap;

			.times {
				margin-left: 10px;
				cursor: pointer;
			}
		}
	}

	.control-label,
	.form-control {
		white-space: nowrap;
		font-size: 12px;
		padding-top: 0px;
		padding-bottom: 0px;
		height: 20px !important;
		margin-bottom: 0px;
	}

	.second-row {
		background: #f3f2ec;
		border-radius: 4px;
		height: 30px;
		padding: 4px 5px 4px 30px;
		font-size: 14px;

		.pagination-wrapper {
			flex-grow: 2;
		}

		.pagination-content {
			display: flex;

			.pagination-title {
				color: #aba99a;
				width: 145px;
				white-space: nowrap;
				text-transform: capitalize;

				span {
					padding: 0px 2px;
				}
			}
		}

		.availableOnly {
			flex-grow: 2;

			.custom-checkboxfilter-option-input {
				display: inline-block;

				input {
					margin-top: 3px;

					&:not(:checked) {
						background: white;
					}
				}
			}
		}

		.form-group {
			margin-bottom: 0px;
			margin-right: 10px;
			display: table;

			label,
			.form-control {
				display: table-cell;
			}

			.form-control {
				border: none;
				max-width: 140px;
				background: transparent;
			}
		}

	}

	select#formControlsSelect.form-control {
		padding: 0px;
		height: 24px;
		font-size: 14px;
	}

	.sort-direction {
		&:HOVER {
			background: #ccc;
			color: #FFF;
			cursor: pointer;
			border-radius: 3px;
		}
	}

	.show-all {
		margin-left: 15px;
	}

}

.custom-checkbox {
	display: inline-block;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	.unchecked {
		width: 18px;
		height: 18px;
		display: inline-block;
		background: #e6dec8;
		position: absolute;
		left: 0;
		pointer-events: none;
	}

	input {
		position: absolute;
		cursor: pointer;
		opacity: 0;
		height: 18px;
		width: 18px;
		left: 0;

	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 18px;
		width: 18px;
		background-color: #eee;
		pointer-events: none;
	}

	input:checked~.checkmark {
		background-color: #676767;
		pointer-events: none;
	}

	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
		pointer-events: none;
	}

	/* Show the checkmark when checked */
	input:checked~.checkmark:after {
		display: block;
		pointer-events: none;
	}

	/* Style the checkmark/indicator */
	.checkmark:after {
		left: 6px;
		top: 2px;
		width: 7px;
		height: 12px;
		border: solid white;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		pointer-events: none;
	}

}

.advanced-filter-menu {

	font-size: 14px;

	.check-filters {
		position: absolute;
		top: 20px;

		.checkbox {
			display: inline-block;
			width: 16px;
			height: 16px;
			border-radius: 3px;
			transition: all 150ms;
		}

		.checkbox-label {
			font-size: 14px;
			vertical-align: middle;
		}

		.east-jerusalem {
			margin-left: 30px;
		}

		input {

			// margin-top: 6px;
		}
	}

	.dropdown-toggle {

		background: #fff;
		color: #676767;
		font-size: 14px;
		z-index: 1001 !important;
	}

	&.open .dropdown-toggle {
		border: 1px solid #e6dec8;
		border-bottom-color: #FFF;
		border-radius: 0px;
	}

	.dropdown-menu {
		margin-top: -1px;
		width: 840px;
		border: 1px solid #e6dec8;
		border-radius: 0px;
		padding-top: 60px;
		padding-left: 65px;
		padding-bottom: 15px;

		.close-btn {
			background: #676767;
			height: 30px;
			padding: 0px 10px;
			@include border-radius(4px);
			display: inline-block;
			position: absolute;
			top: 15px;
			right: 15px;
			color: #FFF;
			line-height: 30px;
			text-transform: capitalize;
			font-size: 14px;
			cursor: pointer;
		}

		.content-wrapper {
			border-top: 1px solid #e6dec8;
			max-height: 600px;
			overflow-y: auto;

		}


	}

	.range-input {

		input,
		.date-range-widget>div {
			height: 25px;
			border: $input-border;
			background: $input-background;
			line-height: 1;
			text-indent: 5px;
			font-size: 12px;
		}

		input {
			width: 125px;
		}

		&.date span {
			line-height: 24px;
		}

		span {
			padding: 0px 5px;
		}
	}

	&.items {
		.dropdown-menu {
			padding: 30px;
		}

		.close-btn {
			display: none;
		}

	}

	&.open .dropdown-menu {
		display: block;
	}

}

.filter-panel {
	background: white;
	// position: fixed;
	z-index: 10;
	padding-left: 0px;
}

.empty-message {
	text-align: center;
	padding: 150px 50px;
}

.property-form.button,
.property-form.button:HOVER {
	background: $gold;
	border-radius: 4px;
	font-size: 1.2em;
	color: #fff;
	padding: 7px 15px;
	white-space: nowrap;
}

.date-range-widget {
	width: 250px;
	position: relative;
}

.range-calendar {
	position: absolute;
	z-index: 10;
	display: table;

}

.results {
	// padding-top: 110px;
}

.filter-item {
	margin-bottom: 15px;

	.filter-title {
		margin: 10px 0px 10px;
	}

	&:First-Child .filter-title {
		margin-top: 20px;
	}

	.filter-options {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 10px;
	}

	.filter-options .filter-option-input {
		width: 140px;
		margin-right: 10px;
		margin-bottom: 15px;
		font-size: 14px;
	}
}