.property-header {
	margin: 30px 0px;

	.back {
		font-size: 40px;
		cursor: pointer;
	}

	.property-title {
		font-size: 22px;
		line-height: 24px;
		margin-top: 5px;
		font-weight: 600;
	}

	.property-contactus {
		position: absolute;
		right: 12px;
	}

	.property-summary {
		font-size: 13px;
		color: #aaaaaa;
	}

	a.jbutton,
	a.jbutton:HOVER {
		padding-top: 10px;
		padding-bottom: 10px;
		line-height: 17px;
		height: auto;
		font-size: 15px;
	}

	.social {
		display: inline-block;
		vertical-align: middle;
		margin-right: 15px;

		.icon,
		.separator {
			display: inline-block;
			vertical-align: middle;
			color: #aaa;
		}

		.icon {
			font-size: 24px;
			cursor: pointer;
		}

		.separator {
			font-size: 14px;
			padding: 0px 5px;
		}

	}

	.contact-us {
		display: inline-block;
		cursor: pointer;

		button {
			height: 40px;
			line-height: 40px;
		}
	}

	.col-sm-5 {
		padding-right: 0px;
	}
}