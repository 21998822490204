@import '../aos/aos';

.intro-page-wrapper{
    .lft-side-bg, .lft-side-bg2, .rt-side-bg{
        position: absolute;
        z-index: 0;
    }
    .lft-side-bg{
        left: 0px;
        top: 500px;
        
    } 
    .rt-side-bg{
        right: 0px;
        top: 2000px;
    }
    .lft-side-bg2{
        left: 0px;
        top: 2700px;
    }
    .header-bg{
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 0;
    }
    .more{
        float: right;
        position: relative;
    }
    .slick-slider .slick-prev:before{
        background: url('../../../../public/images/intro/left_arrow.png') no-repeat center center;
        font-size: 87px;
    }
    .slick-slider .slick-next:before{
        background: url('../../../../public/images/intro/rt_arrow.png') no-repeat center center;
        font-size: 87px;
    }
    .section{
        text-align: center;
        margin: 30px auto 100px;

        
        max-width: 900px;
        .title{
            font-size: 1.5em;
            font-weight: bold;
            margin-bottom: 20px;
        }
        .button{
            margin-top: 45px;
        }
    }
    .section-title{
        font-size: 32px !important;
        // font-weight: bold;
        font-style: normal !important;
        margin-bottom: 40px;
    }
    .contact-header{
        padding: 50px 0px;
    }
    .testimonial-content{
        .text{
            padding: 0px 30px;
        }
    }
    .footer{
        position: relative;
    }
}

.header-intro{
    padding: 60px 0px;
    position: relative;
}
.split > div{
    display: inline-block;
    vertical-align: middle;
    width: 50%;

    &:last-child{
        width: 35%;
    }
}
.intro-page{
    .floating-menu{
        position: fixed;
        left: 30px;
        a{
            display: block;
            margin-bottom: 10px;
        }
        a.contact-us span{
            color: $gold;
            cursor: pointer;
            &:HOVER{
                text-decoration: underline;
            }
        }
    }
    .introduction{
        .title{
            max-width: 70%;
            font-weight: normal;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .content{
        margin: 0 auto;
        color: #676767;
        position: relative;
    }

    .title{
        text-align: center;
        font-size: 30px;
        margin-bottom: 90px;
    }

    .why{
        margin-top: 80px;
        .title{
            text-transform: uppercase;
            font-size: 56px;
            font-weight: bold;
            margin-bottom: 50px;
        }
        p{
            max-width: 720px;
            margin-left: auto;
            margin-right:auto;
        }
    }
    .down-arrow{
        border: 1px solid $default-font-color;
        @include border-radius(25px);
        width: 40px;
        height: 40px;
        margin: -60px auto 60px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        position: relative;
        cursor: pointer;
        &:BEFORE{
            content:"";
            width: 12px;
            height: 12px;
            border-left: 1px solid $default-font-color;
            border-bottom: 1px solid $default-font-color;
            display: inline-block;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -4px;
            margin-top: -8px;
        }
    }
    .block-section{
        @include flexbox();
        margin-bottom: 60px;

        >div{
            padding: 20px; 
            width: 50%;
        }
        >div:first-child{
            text-align: right;
        }
        >div:last-child{
            text-align: left;
        }
        .block-title{
            font-size: 48px;
            font-weight: bold;
            line-height: 70px;
            margin-bottom: 40px;
        }
        .quote-text{
            padding-left: 100px;
            margin-bottom: 30px;
            margin-top: 40px;
            font-size: 1.2em;
            font-style: italic;
        }
        .person{
            font-weight: bold;
        }
        &.left{
            .quote{
                padding-left: 50px;
            }
            .quote-text, .person, .company{
                padding-right: 20px;
            }
            .quote-text{
                border-right: 5px solid $gold;
            }
        }
        
        &.right{
            @include flex-flow(row-reverse);
            .quote{
                text-align: left;
                padding-right: 50px;
            }   
            .quote-text, .person, .company{
                padding-left: 20px;
            }
            .quote-text{
                border-left: 5px solid $gold;
            }
            .message{
                text-align: right;
            }
        }
        
        &.one-stop{
            .image{
                margin-top: 25px;
                margin-bottom: 63px;
            }
        }
        &.property{
            .image{
                margin-top: 25px;
                margin-bottom: 50px;
            }
        }
        &.service{
            .image{
                margin-top: 25px;
                margin-bottom: 50px;
            }
        }
        &.client{
            .quote-text{
                margin-top: 25px;
            }
        }
    }
    
    
}
.homepage-section{
    // margin-top: -40px;
}