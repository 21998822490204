.google-map {
  height: 400px;
  width: 100%;
}
.map-container{
	width: 100%;
	height: 100%;
}
 
.map-search-box{
	height: 28px;
	line-height: 28px;
	text-indent: 10px;
	margin: 10px;
	width: 200px;
}
@media screen and (min-width: 1024px){
    .map {
        height: 500px;
    }
}


.gm-style-iw.gm-style-iw-c{
	padding: 0px;
	max-width: 840px !important;
	button{
		right: 9px !important;
		top: 0px !important;
		
		&>img{
			// margin-top: 15px !important;
			width: 24px !important;
			height: 24px !important;
		}
	}
}
.gm-style-iw-d{
	max-height: 100% !important;
	overflow: visible !important;
}