$titles-color: #ca9821;

.gold-title {
    font-size: 36px;
    font-weight: bold;
    color: $titles-color;
    text-transform: uppercase;
    line-height: 1.4em;
    margin-bottom: 30px;
    font-family: 'Trirong', sans-serif;

}

.profile-page {
    font-family: 'Open Sans', sans-serif;
    .header-wrapper{
        padding-top: 50px;
        background: #fcfbf8;
        padding-bottom: 40px;
        border-top: 1px solid #ebeae7;
        border-bottom: 1px solid #ebeae7;
    }
    .profile-header {
        margin-bottom: 70px;
        font-family: 'Trirong', sans-serif;
        
        .main-title {
            text-align: center;
            font-size: 48px;
            font-weight: 700;
            color: $titles-color;
            letter-spacing: 0.52px;
            line-height: 42px;
        }
        
        .sub-title {
            text-align: center;
            font-weight: 400;
            font-size: 30px;
            letter-spacing: 0.46px;
            line-height: 42px;
        }
    }

    .tareq-section{
        padding-top: 50px;
        background: #f7f6f2;
        padding-bottom: 40px;
        border-top: 1px solid #ebeae7;
        border-bottom: 1px solid #ebeae7;
        margin: 0 auto;

        .tarek-name {
            white-space: nowrap;
            margin-top: 10px;
            font-weight: bold;
            font-size: 17px;
            color: #676767;
        }
        .tarek-title{
            font-size: 14px;
            color: #878787;
            font-style: italic;
        }
        .signature{
            margin-top: 20px;
        }
    }
    .why-work-section{
        padding-top: 50px;
        padding-bottom: 50px;
        text-align: center;
        .titles-section{
            font-family: "Trirong", serif;
            text-align: right;
            margin-right: 73px;
            .why-title{
                font-size: 29px;
                color: #666;
            }
            .jr-title{
                font-size: 42px;
                color: #ca9821;
                line-height: 45px;
                font-weight: 700;
            }
        }
    }
    .customer-centric{
        .title{
            font-family: Trirong, serif;
            font-size: 25px;
            color: #ca9821;
            font-weight: 700; 
            text-align: center;
            margin-bottom: 20px;
        }
        .text{
            max-width: 974px;
            margin: 0 auto;
            border-radius: 20px;
            background: #F7F6F2;
            border: 1px solid #ebeae7;
            text-align: center;
            font-size: 15px;
            line-height: 25px;
            color: #938461;
            p{
              padding-bottom: 5px;  
            }
        }
    }
    .extensive-listing{
        max-width: 974px;
        margin: 20px auto;
        border-radius: 20px;
        background: #fcfbf8;
        border: 1px solid #ebeae7;
        padding-top: 78px;
        padding-bottom: 78px;

        .title{
            font-family: Trirong, serif;
            font-weight: 700;
            font-size: 25px;
            color: #ca9821;
        }
        .content{
            font-size: 15px;
            color: #878787;
            line-height: 24px;
            text-align: center;
            margin-top: 30px;
        }
    }
    .title{
        font-family: Trirong, serif;
        font-weight: 700;
        font-size: 25px;
        color: #ca9821;
    }
    .high-quality{
        margin-top: 80px;
        .text{
            max-width: 974px;
            margin: 0px auto;
            border-radius: 20px;
            background: #f7f6f2;
            border: 1px solid #ebeae7;
            padding-top: 65px;
            padding-bottom: 65px;
            text-align: center;
            font-size: 15px;
            line-height: 24px;
            color: #938461;
            margin-bottom: 20px;
        }
    }
    .full-info{
        max-width: 974px;
        margin: 20px auto;
        border-radius: 20px;
        background: #fcfbf8;
        border: 1px solid #ebeae7;
        padding-top: 78px;
        padding-bottom: 78px;
        .text{
            font-family:'Open Sans', sans-serif;
            font-size:15px;
            color:#878787; 
            line-height:24px;
            text-align:center;
            padding-top: 30px;
        }
    }
    .full-info{
        max-width: 974px;
        margin: 20px auto 0px;
        border-radius: 20px;
        background: #fcfbf8;
        border: 1px solid #ebeae7;
        padding-top: 78px;
        padding-bottom: 78px;
        .text{
            font-family:'Open Sans', sans-serif;
            font-size:15px;
            color:#878787; 
            line-height:24px;
            text-align:center;
            padding-top: 30px;
        }
    }
    .wide{
        // max-width: 974px;
        margin: 20px auto 0;
        border-radius: 20px 20px 0px 0px;
        background: #aaa390;
        border-bottom: 4px solid #cb9a22;
        padding-top: 78px;
        padding-bottom: 78px;
        color:#FFF; 
        width: 100%;
        .title{
            color:#FFF; 
        }
        .text{
            max-width: 722px;
            margin: 0 auto;
            font-family:'Open Sans', sans-serif;
            font-size:15px;
            color:#FFF; 
            line-height:24px;
            text-align:center;
            padding-top: 30px;
        }
    }
    .virtual-tours{
        max-width: 974px;
        margin: 10px auto 0px;
        border-radius: 20px 20px 0px 0px;
        background: #f7f6f2;
        border-top: 1px solid #ebeae7;
        border-left: 1px solid #ebeae7;
        border-right: 1px solid #ebeae7;
        padding-top: 78px;
        padding-bottom: 78px;
        width: 100%;
        .text{
            max-width: 722px;
            margin: 0 auto;
            font-family:'Open Sans', sans-serif;
            font-size:15px;
            color:#938461; 
            line-height:24px;
            text-align:center;
            padding-top: 30px;
        }
    }
    .custom-listing{
        margin-top: 100px;
        background: #fcfbf8;
        border-top: 1px solid #ebeae7;
        border-bottom: 1px solid #ebeae7;
        padding-top: 90px;
        padding-bottom: 80px;
        text-align: center;
        .title{
            font-size: 42px;
            line-height: 42px;
        }
        .sub-title{
            font-family: 'Trirong', serif;
            font-size: 29px;
            color: #666666;
            margin-bottom: 30px;
        }
        .text{
            font-size: 15px;
            color: #878787;
            padding: 50px;
        }
    }
    .stats-banner{
        color: #FFF;
        .number{
            font-family: Trirong, serif;
            font-size: 30px;
            line-height: 30px;
            font-weight: 700;
            margin-top: 30px;
            margin-bottom: 25px;
            .plus{
                font-weight: 300;
                font-size: 20px;
            }
        }
        .label{
            font-size: 15px;
        }
        .dot{
            font-size: 30px;
            width: 114px;
            text-align: center;
        }
    }

    .our-services{
        padding-top: 80px;
        // padding-bottom: 110px;
        .text{
            margin: 0px auto;
            border-radius: 20px;
            background: rgb(247, 246, 242);
            padding: 55px 100px 35px;
            text-align: center;
            font-size: 15px;
            line-height: 24px;
            color: rgb(147,132,97);
            margin-bottom: 10px;
            .head{
                font-size: 15px;
                line-height: 24px;
                font-weight: bold;
                margin-bottom: 15px;
            }
        }
    }
    .blog{
        padding-top: 70px;
        padding-bottom: 70px;
        .title{
            color: #FFF;
        }
    }


    .about {
        font-style: italic;
        color: #878787;
        text-align: center;
        font-size: 18px;
        padding-top: 20px;
        max-width: 650px;
        margin-bottom: 30px;
        line-height: 28px;
        font-weight: 300;
        margin: 50px auto;
    }

    .backgrounded {
        background: #F7F6F2;
        padding: 25px 30px;
        position: relative;
        color: #938461;
        border-radius: 20px;
        // font-size: 14px;
        line-height: 21px;
        

        &.italic {
            * {
                font-style: italic;
            }
        }

        &.bullets {
            padding: 50px 100px;
    
            .bullet {
                position: relative;
                padding: 20px 0px 50px;
                font-size: 18px;
    
                &:before {
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 1px;
                    background: #aaa390;
                    top: 10px;
                    left: 0;
                }
            }
        }
    }


      


    .our-services-bg {
        background: url(../../../../public/images/profile/services_iamge.png);
        background-size: cover;
    }

    .semibold {
        font-weight: 600;
    }

    .italic {
        font-style: italic;
    }

    .bordered {
        border: 10px solid #e4e0d7;
        border-radius: 15px;

        img {
            margin-bottom: 15px;
        }


    }

    .box-title {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px;
        line-height: 1.3em;
        margin-bottom: 15px;
        color: #666;
    }

    .box-content {
        color: #938461;
        font-size: 14px;
    }

    .stepper{
        
        border: 5px solid transparent;
        border-bottom-color: #F8F8F8;
        height: 190px;
        margin-top: -10px;
        
        &.left{
            border-left-color: #F8F8F8;
            border-bottom-left-radius: 20px;
        }
        &.right{
            border-right-color: #F8F8F8;
            border-top-right-radius: 20px;
        }

        .number{
            background: $titles-color;
            width: 40px;
            height: 40px;
            border-radius:50px;
            border: 5px solid #F8F8F6;
            position: absolute;
            text-align: center;
            font-size: 22px;
            font-weight: bold;
            font-family: 'Trirong';
            color: #FFF;
        }

    }
    .languages{
        img{
            position: absolute;

            &.left{
                left:-50px;
            }
            &.right{
                right:-50px;
            }
        }
    }

    .why-customers-title{
        font-size: 21px;
        font-weight: bold;
        line-height: 25px;
    }
    .why-customers-content{
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        color: #938461;
        padding-left: 128px;
    }

}