.new_testimonial{
	font-size: 13px;

}
.edit-propery-page-title{
	padding: 15px;
	text-align: center;
}
.card-title{
	border-bottom: 1px solid #efeced;
	color:  #CCC;
	padding-bottom: 10px;
}
.testimonial-in-list, .new_testimonial{
	display: inline-block;
	vertical-align: top;
	width: 150px;
	height: 150px;
	border:  1px solid $grey-brown-trans;
	@include border-radius(4px);
	padding: 10px;
	font-size: 0.8em; 
	margin-bottom: 15px;
	margin-right: 15px;
	position: relative;
	a{
		position: absolute;
		bottom: -14px;
		right: 5px;
		background: $grey-brown;
		color: #FFF !important;
		padding: 0px 10px;
		display: inline-block;
		font-size: 0.85em;
		line-height: 1.25em;
		@include border-radius(0px 0px 4px 4px);
		cursor: pointer;
	}
	.text-wrapper{
		.text{
			font-size: 0.8em;
			line-height: 1.5em;
			max-height: 100px;

			display: block;
			display: -word-box;

			margin: 0 auto;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;

			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

}
.new_testimonial{
	color: $gold;
	font-size: 0.8em;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
	padding-top: 60px;
}
.modal-dialog {
	.form-group input{margin-bottom: 10px;}
	.btn-primary{margin-left:10px;}
}