.closable-header{
	
	text-align: center;
	max-height:  500px;
	@include transition(all 1s ease-in-out);

	.container{
		border-bottom:  1px solid #919191;
		padding-bottom: 25px;
		position: relative;
	}

	.title{
		font-size: 24px;
		padding: 50px 0px 25px;
		text-transform: capitalize;
	}
	.message{
		font-size: 14px;
		max-width: 710px;
		margin: 0 auto;
	}

	.close{
		position: absolute;
		top: 50px;
		right: 25px;
		font-size: 16px;
	}

	&.closed{
		max-height: 0px;
		overflow: hidden;
		opacity: 0;
	}
}